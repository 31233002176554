<template>
  <div class="importPartyOrg">
    <import-template
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="false"
      :otherParams="otherParams"
      label="社群信息"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
import { _localStorage } from "@/utils/utils.js";
export default {
  name: "importPartyOrg",
  data() {
    return {
      otherParams: {
        className: "OpsSzdzCommunityInformation",
        mapper: "OpsSzdzCommunityInformationMapper",
        tenantId: "",
        futureCommunityCode: "",
      },
      downloadUrl: "/static/excel/上报社群信息.xlsx",
      uploadImgUrl: "/gateway/hc-portal/szdz/importData",
    };
  },
  components: {
    importTemplate,
  },
  created() {
    const { futureCommunityCode } = this.$route.query;
    if (futureCommunityCode) {
      this.otherParams.futureCommunityCode = futureCommunityCode;
    }
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.otherParams.tenantId = userInfo.tenantId;
    }
  },
  mounted() {
    this.$setBreadList("导入社群信息");
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.importPartyOrg {
  height: calc(100% - 98px);
  box-sizing: border-box;
  background: #fff;
  ::v-deep .content {
    margin-left: 20px;
    justify-content: flex-start;
  }
}
</style>
